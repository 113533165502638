<template>
    <div class="material zhong">
        <div class="topfixed">
            <!--    头部导航    ?-->
            <hd-com></hd-com>
            <!--    banner    ?-->
            <div class="banner">
                <div class="banner-text">
                    <h3>{{ materialDetail.materialName }}<span v-if="materialDetail.MaterialAlias">（别名：{{ materialDetail.MaterialAlias }}）</span></h3>
                    <p>当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><router-link
                            to="/Drug/Material">中药库</router-link>
                        <i class="el-icon-arrow-right"></i><a>{{ materialDetail.materialName }}</a>
                    </p>
                    <div class="banner-right">
                        <!-- <span>加入配伍</span> -->
                        <span @click="onCompatibility(materialDetail)">加入配伍</span>
                        <span v-if="!exist" @click="onCollection(materialDetail)">加入收藏</span>
                        <span v-else @click="deleteAll()">取消收藏</span>
                    </div>
                </div>
            </div>
        </div>
        <!--    内容部分    ?-->
        <div class="content-wrap" v-if="materialDetail != null && Object.keys(materialDetail).length != 0">
            <div class="content clearfix">
                <el-scrollbar class="left-nav-wrap">
                    <!-- 具体要包裹的内容 -->
                    <div class="fast-nav">
                        <ul class="timeline is-reverse">
                            <li class="timeline-item"
                                v-for="(item, index) in materialDetail.infos.filter(i => i.title != '中药图片')"
                                :key="index" v-show="item.info" @click="jump(index)">
                                <div class="timeline-item__tail"
                                    v-if="!materialDetail.category && index != materialDetail.infos.filter(i => i.title != '中药图片').length - 1">
                                </div>
                                <div class="timeline-item__tail" v-if="materialDetail.category"></div>
                                <div class="timeline-item__node"></div>
                                <div class="timeline-item__text">
                                    <h3 data-size="18">{{ item.title }}</h3>
                                </div>
                            </li>
                            <li class="timeline-item" v-if="materialDetail.category"
                                @click="jump(materialDetail.infos.length-1)">
                                <div class="timeline-item__node ">
                                </div>
                                <div class="timeline-item__text">
                                    <h3 data-size="18">同类药</h3>
                                </div>
                            </li>
                        </ul>
                    </div>
                </el-scrollbar>
                <div class="detail-box"
                    v-if="materialDetail.infos != null && Object.keys(materialDetail.infos).length != 0">
                    <div class="sd-div" v-for="(item, index) in materialDetail.infos.filter(i => i.title != '中药图片')"
                        :key="index" v-show="item.info">
                        <h3 class="sd-tit" data-size="24">{{ item.title }}</h3>
                        <div class="sd-mes">
                            <p data-size="16" v-html="replace(item.info)"></p>
                        </div>
                    </div>
                    <div class="sd-div" v-if="materialDetail.category != 0">
                        <h3 class="sd-tit" data-size="24">同类药 </h3>
                        <el-collapse v-for="(drugRelation, index) in drugRelations" :key="index">
                            <el-collapse-item :title=drugRelation[0].relationType name="index" v-if="drugRelation[0].relationType != '中成药'">
                                <template>
                                    <ul class="zhongyao clearfix" v-if="drugRelation[0].relationType == '中药饮片'">
                                        <li class="zhongyao-item"
                                            v-for="(drugRelationitem, index) in drugRelation[0].items.slice(0, 8)"
                                            :key="index" @click="toMaterialDetail(drugRelationitem.hashKey)">
                                            <div class="zhongyao-img">
                                                <img v-if="drugRelationitem.itemPhoto" :src="drugRelationitem.itemPhoto"
                                                    alt="" />
                                                <img v-else src="../../assets/img/zyc_img.png" alt="" />
                                            </div>
                                            <p class="zhongyao-name">
                                                {{ drugRelationitem.itemName }}
                                            </p>
                                        </li>
                                        <a class="more-btn" v-if="drugRelation[0].items.length >= 8"
                                            @click="toMaterialMore(drugRelation[0].categoryId)">更多...</a>
                                    </ul>
                                    <ul class="hxsw" v-if="drugRelation[0].relationType == '中药方剂'">
                                        <li v-for="(drugRelationitem, index) in drugRelation[0].items" :key="index">
                                            <!-- <el-button type="success" plain
                                                @click="toHerbalDetail(drugRelationitem.hashKey)">
                                                {{ drugRelationitem.itemName }}
                                            </el-button> -->
                                            <div class="hxswsucBtn" @click="toHerbalDetail(drugRelationitem.hashKey)">
                                                {{
                                                    drugRelationitem.itemName
                                                }}
                                            </div>
                                        </li>
                                        <a class="more-btn" v-if="drugRelation[0].HasMore"
                                            @click="toHerbalMore(drugRelation[0].categoryId)">更多...</a>
                                    </ul>
                                    <ul class="hxsw" v-if="drugRelation[0].relationType == '食疗药膳'">
                                        <li v-for="(drugRelationitem, index) in drugRelation[0].items" :key="index">
                                            <!-- <el-button type="success" plain
                                                @click="toFoodDetail(drugRelationitem.hashKey)">
                                                {{ drugRelationitem.itemName }}
                                            </el-button> -->
                                                <div class="hxswsucBtn" @click="toFoodDetail(drugRelationitem.hashKey)">
                                                {{
                                                    drugRelationitem.itemName
                                                }}
                                            </div>
                                        </li>
                                        <a class="more-btn" v-if="drugRelation[0].HasMore"
                                            @click="toFoodMore(drugRelation[0].categoryId)">更多...</a>
                                    </ul>
                                    <ul class="hxsw" v-if="drugRelation[0].relationType == '中成药'">
                                        <li v-for="(drugRelationitem, index) in drugRelation[0].items" :key="index">
                                            <!-- <el-button type="success" plain
                                                @click="toDrugDetail(drugRelationitem.hashKey)">{{
                                                        drugRelationitem.itemName
                                                }}</el-button> -->
                                            <div class="hxswsucBtn" @click="toDrugDetail(drugRelationitem.hashKey)">
                                                {{
                                                    drugRelationitem.itemName
                                                }}
                                                 </div>
                                        </li>
                                        <a class="more-btn" v-if="drugRelation[0].HasMore"
                                            @click="toDrugMore(drugRelation[0].categoryId, drugRelation[0].relationType)">更多...</a>
                                    </ul>
                                    <ul class="hxsw" v-if="drugRelation[0].relationType == '化学药品和生物制品'">
                                        <li v-for="(drugRelationitem, index) in drugRelation[0].items" :key="index">
                                            <!-- <el-button type="success" plain
                                                @click="toDrugDetail(drugRelationitem.hashKey)">{{
                                                        drugRelationitem.itemName
                                                }}</el-button> -->
                                            <div class="hxswsucBtn" @click="toDrugDetail(drugRelationitem.hashKey)">
                                                {{
                                                    drugRelationitem.itemName
                                                }}
                                            </div>
                                        </li>
                                        <a class="more-btn" v-if="drugRelation[0].HasMore"
                                            @click="toDrugMore(drugRelation[0].categoryId, drugRelation[0].relationType)">更多...</a>
                                    </ul>
                                </template>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </div>
            </div>
        </div>
        
        <ft-com></ft-com>
        <rt-bar></rt-bar>
        <staticTime></staticTime>
    </div>
</template>

<script>
import hdCom from "../../components/header"; //引入组件
import ftCom from "../../components/footer"; //引入组件
import rtBar from "../../components/right";
import staticTime from "../../components/staticTime";
import { Material } from '../../components/Drug/Material'
import { DrugExtend } from "../../components/Drug/DrugExtend"
import {  toFood, toHerbal, toDrug, toDrugMore, toHerbalMore, toFoodMore, toMaterialMore } from "../../utils/relation";
import { loginOut } from '../../utils/userInfo'
import { Collection } from "../../components/collection/Collection"
import { addCompare, getCompatibilityList } from '../../utils/Compatibility'
export default {
    name: "materialDetail",
    components: {
        hdCom,
        ftCom,
        rtBar,
        staticTime
    },
    data() {
        var material = new Material(this.TokenClient, this.Services.Drug)
        var drugExtend = new DrugExtend(this.TokenClient, this.Services.Drug)
        var collection = new Collection(this.TokenClient, this.Services.Statics)
        return {
            hashKey: this.$route.query.hashKey,   //列表页id
            searchId: this.$route.query.searchId,    //列表页searchId
            keyWord: this.$route.query.keyWord,   //列表页KeyWord
            materialDetail: {},   //初始化详情
            drugRelations: {},   //初始化同类药
            materialDomain: material,
            drugExtendDomain: drugExtend,
            scroll: '',
            collectionDomain: collection,
            entityData: {},
            fontSize: '',
            exist: false,
            collectionId: ""
        }
    },
    methods: {
        onCompatibility: function (item) {
            var compatibility = getCompatibilityList();
            if (compatibility.length > 0) {
                if (compatibility.some(i => i.hashKey == item.hashKey && i.type == '4')) {
                    this.$message.error('已加入用药审查！');
                    return false;
                }
            }
            addCompare(item.hashKey, item.materialName, 1, "Material");
            this.$message.success('成功加入用药审查！');
            this.$bus.$emit('right', 'reload')
        },
        handleScroll() {
            let scrollY = document.documentElement.scrollTop ||
                window.pageYOffset ||
                document.body.scrollTop;
            let scrollObj = document.querySelector(".left-nav-wrap");
            let topfixed = document.querySelector(".topfixed");
            //scrollObj 这个是下图右边答题卡的div

            if (scrollY > 80) {
                topfixed.style.position = "fixed";
                topfixed.style.top = 0;
                topfixed.style.zIndex = 99;
                scrollObj.style.position = "fixed";
                scrollObj.style.top = "100px";
            } else {
                topfixed.style.position = "initial";
                topfixed.style.top = "none";
                scrollObj.style.position = "absolute";
                scrollObj.style.top = "auto";
            }
        },
        checkExist() {
            var _this = this;
            _this.collectionDomain.Exist(this.entityData.type, this.entityData.hashKey, this.entityData.id,
                function (data) {
                    _this.exist = data.data.exist
                    _this.collectionId = data.data.collectionId

                }, function (err) {

                });
        },
        //详情获取
        getMaterialDetail: function () {
            var _this = this;
            let params = `${this.hashKey}`
            _this.materialDomain.Detail(params, function (data) {
                _this.materialDetail = data.data
                if (data.data.category != null) {
                    //获取同类药
                    _this.getRelation(data.data.category.categoryName)
                }
                _this.entityData = {
                    type: "material",
                    name: _this.materialDetail.materialName,
                    hashKey: _this.materialDetail.hashKey,
                    id: _this.materialDetail.materialId
                }
                _this.checkExist()
                _this.$bus.$emit('fontSize');
            },
                function (error) {
                    console.log("中药详情请求异常:", error)
                })
        },
        //获取同类药
        getRelation: function (CategoryName) {
            var _this = this;
            _this.drugExtendDomain.Relation(CategoryName, function (data) {
                _this.drugRelations = data.data;
            },
                function (error) {
                    console.log("同类药请求异常", error)
                })
        },
        deleteAll: function () {
            var _this = this;
            var idarr = [_this.collectionId]
            _this.collectionDomain.Delete(idarr, function (data) {
                _this.checkExist()
                _this.$message({
                    center: true,
                    type: 'success',
                    message: '取消成功!'
                });
            },
                function (error) {
                    console.log(error);
                })
        },
        onCollection: function (item) {
            var _this = this;
            _this.collectionDomain.Put("material", item.materialName, item.hashKey, item.materialId, 0,
                function (data) {
                    _this.checkExist()
                    _this.$message({
                        type: 'success',
                        message: '收藏成功!'
                    });
                }, function (err) {
                    if (err.status === 400) {
                        _this.$message.error('请勿重复收藏');
                    }
                });
        },
        //跳转同类药-中药更多
        toMaterialMore(cateid) {
            toMaterialMore(cateid);
        },
        //跳转同类药-中药详情
        toMaterialDetail(hashKey) {
            this.hashKey = hashKey
            this.getMaterialDetail()
        },
        //跳转同类药-食疗药膳更多
        toFoodMore(cateid) {
            toFoodMore(cateid);
        },
        //跳转同类药-食疗药膳详情
        toFoodDetail(hashKey) {
            toFood(hashKey);
        },
        //跳转同类药-中药方剂更多
        toHerbalMore(cateid) {
            toHerbalMore(cateid);
        },
        //跳转同类药-中药方剂详情
        toHerbalDetail(hashKey) {
            toHerbal(hashKey);
        },
        //跳转同类药-中西药更多
        toDrugMore(cateid, type) {
            toDrugMore(cateid, type);
        },
        //跳转同类药-中西药详情
        toDrugDetail(hashKey) {
            toDrug(hashKey);
        },
        replace: function (content) {
            return content.replace(/\n/g, '</p><p>');
        },
        dataScroll: function () {
            this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
        },
        jump(index) {
            let jump = document.getElementsByClassName('sd-div');
            // 获取需要滚动的距离
            let total = jump[index].offsetTop - 250;
            window.scroll(0, total)
            // Chrome
            //document.body.scrollTop = total;
            // Firefox
            //document.documentElement.scrollTop = total;
            // Safari
            //window.pageYOffset = total;
            // $('html, body').animate({
            // 'scrollTop': total
            // }, 400);

        },
        loadSroll: function () {
            var self = this;
            var $navs = $(".timeline .timeline-item");
            var sections = document.getElementsByClassName('sd-div');
            for (var i = sections.length - 1; i >= 0; i--) {
                if (self.scroll >= sections[i].offsetTop - 250) {
                    $navs.eq(i).addClass("active").siblings().removeClass("active")
                    break;
                }
            }
        }
    },
    watch: {
        scroll: function () {
            this.loadSroll();
        },
    },
    mounted() {
        this.$bus.$emit('select', 'material')
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("scroll", this.dataScroll);
        this.getMaterialDetail()
    },
    destroyed() {
        //销毁监听
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("scroll", this.dataScroll);
    },

}
</script>

<style scoped>
.hxswsucBtn {
    display: inline-block;
		padding: 5px 15px;
		margin: 7px 12px 5px 0;
		border-radius: 2px;
		font-size: 14px;
		color: #b76c12;
		background-color: #FFFFFF;
		border: 1px solid #b76c12;
        cursor: pointer;
}
.hxswsucBtn:hover {
    display: inline-block;
		padding: 5px 15px;
		margin: 7px 12px 5px 0;
		border-radius: 2px;
		font-size: 14px;
		color: #f3f0eb;
		background-color: #b76c12;
		border: 1px solid #b76c12;
        cursor: pointer;
}
.zyheader .header {
    height: 80px;
    background: #ffffff;
}

.zyheader .head-con {
    width: 1440px;
    margin: 0 auto;
}

.zyheader .logo {
    line-height: 80px;
    margin-right: 20px;
}

.zyheader .logo img {
    margin-top: 15px;
}

.zyheader .navbar ul li {
    float: left;
    padding: 20px 25px;
    line-height: 40px;
    position: relative;
}

.zyheader .navbar ul li a {
    display: inline-block;
    position: relative;
    border-width: 0;
    font-size: 16px;
    line-height: 40px;
}

.zyheader .navbar ul li:hover>a {
    display: inline-block;
    color: #b76c12;
}

.zyheader .navbar ul li>a::before {
    content: "";
    position: absolute;
    /* 重点 */
    left: 0;
    /* 重点 */
    bottom: 0;
    /* 重点 */
    width: 0;
    /* 重点 */
    height: 2px;
    background: #b76c12;
    transition: width .5s;
}

.zyheader .navbar ul li:hover>a::before {
    width: 100%;
}

.zyheader .navbar ul li.active>a::before {
    width: 100%;
}

.zyheader .navbar ul li.active>a {
    color: #b76c12;
}

.zyheader .navbar ul li:hover .sub-nav {
    display: block;
}

.zyheader .sub-nav {
    display: none;
    position: absolute;
    top: 62px;
    padding: 10px 0;
    left: -40%;
    width: 150px;
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 2px 0px 2px #f0f0f0;
    z-index: 9;
}

.zyheader .sub-nav dt a {
    display: block !important;
    text-align: center;
    font-size: 14px !important;
    color: #333;
}

.zyheader .sub-nav dt:hover a {
    color: #b76c12 !important;
}

.zyheader .personalCenter {
    margin: 0 100px 0 70px;
    position: relative;
    float: right;
    font-size: 14px;
    color: #333;
}

.zyheader .personalCenter>p {
    text-align: center;
    line-height: 80px;
}

.zyheader .personalCenter:hover .per-hover {
    display: block;
}

.zyheader .personalCenter .login-txt {
    text-align: center;
    line-height: 80px;
    cursor: pointer;
}

.zyheader .per-hover {
    display: none;
    position: absolute;
    left: -125px;
    width: 300px;
    height: 250px;
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 3px 0px 5px #D9D9D9;
    z-index: 99;
}

.zyheader .head-box {
    padding-top: 15px;
    background: #f9f9f9;
}

.zyheader .head-img {
    margin: 0 auto;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
}

.zyheader .head-img img {
    width: 100%;
    height: 100%;
}

.zyheader .head-box>p {
    line-height: 45px;
    text-align: center;
}

.zyheader .per-info li {
    margin: 5px 10px;
    float: left;
    width: 76px;
    height: 68px;
}

.zyheader .per-info li p {
    line-height: 1;
    text-align: center;
}

.zyheader .per-info li:hover {
    background: #b76c12;
}

.zyheader .per-info li:hover p {
    color: #ffffff;
}

.zyheader .s-icon {
    width: 25px;
    height: 25px;
    margin: 10px auto;
}

.zyheader .s-icon1 {
    background: url("../../assets/img/s_icon1.jpg") center no-repeat;
}

.zyheader .s-icon2 {
    background: url("../../assets/img/s_icon2.jpg") center no-repeat;
}

.zyheader .s-icon3 {
    background: url("../../assets/img/s_icon3.png") center no-repeat;
}

.zyheader .per-info li:hover .s-icon1 {
    background: url("../../assets/img/w_icon1.png") center no-repeat;
}

.zyheader .per-info li:hover .s-icon2 {
    background: url("../../assets/img/w_icon2.png") center no-repeat;
}

.zyheader .per-info li:hover .s-icon3 {
    background: url("../../assets/img/w_icon3.png") center no-repeat;
}

.zyheader .status {
    border-top: 1px solid #ebebeb;
}

.zyheader .status span {
    display: inline-block;
    font-size: 14px;
    color: #333;
    width: 49%;
    text-align: center;
    line-height: 60px;
}

.search {
    margin-top: 20px;
    padding-left: 10px;
    height: 40px;
    line-height: 38px;
    border: 1px solid #b76c12;
    border-radius: 20px;
    box-sizing: border-box;
    overflow: hidden;
}

.search input {
    width: 200px;
    padding: 0 5px;
}

.search .search-btn {
    width: 55px;
    height: 38px;
    background: #b76c12 url("../../assets/img/search.png")center no-repeat;
    vertical-align: top;
}

/*banner*/
.banner {
    width: 100%;
    height: 160px;
    background: url("../../assets/img/zyk_banner.jpg")center no-repeat;
}

.banner-text {
    position: relative;
    width: 1200px;
    margin: 0 auto;
}

.banner-text h3 {
    padding-top: 45px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text h3 span {
    font-size: 14px;
}

.banner-text p {
    margin-top: 10px;
    font-size: 14px;
    color: #ffffff;
}

.banner-text p a {
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
}

.banner-right {
    position: absolute;
    right: 5px;
    top: 65px;
}

.banner-right span {
    display: inline-block;
    margin-left: 20px;
    width: 132px;
    height: 32px;
    border: 1px solid #ffffff;
    border-radius: 32px;
    box-sizing: border-box;
    text-align: center;
    color: #ffffff;
    line-height: 30px;
    cursor: pointer;
}

.banner-right span:hover {
    background-color: #ffffff;
    color: #b76c12;
}

/*内容部分*/
.content-wrap {
    background: #f8f8f8;
}

.content-wrap .content {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 60px;
    background-color: rgba(255, 255, 255, 0);
}

.detail-box {
    margin-right: 260px;
    padding: 0px 35px;
    width: 830px;
    background: #FFFFFF;
    box-shadow: 5px 0 8px rgba(224, 224, 224, 0.3);
}

.sd-div {
    padding: 30px 0;
    border-top: 1px solid #e5e5e5;
}

.sd-div:first-child {
    border-top: 0px;
}

.sd-div .sd-tit {
    font-size: 24px;
    color: #333333;
    font-weight: normal;
    margin-bottom: 30px;
}

.sd-div .sd-tit.overview {
    font-size: 26px;
    color: #333333;
    padding-left: 15px;
    border-left: 3px solid #333;
    line-height: 26px;
}

.sd-div .sd-mes p {
    white-space: pre-line;
}

.hxsw li {
    margin: 2px 0;
    display: inline-block;
    height: 42px;
    /* border: 1px solid #999999; */
    padding: 0 5px;
    box-sizing: border-box;
}

.hxsw li a {
    line-height: 42px;
    text-align: center;
    color: #666666;
    font-size: 16px;
    cursor: pointer;
}

.hxsw li:hover {
    border-color: #b76c12;
}

.hxsw li:hover a {
    color: #b76c12;
}

.zhongyao {
    text-align: center;
}

.zhongyao li {
    float: left;
    width: 184px;
    height: 184px;
    margin: 10px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
}

.zhongyao li .zhongyao-img {
    width: 120px;
    height: 120px;
    margin: 15px auto 5px;
}

.zhongyao li .zhongyao-img img {
    width: 100%;
    height: auto;
}

.zhongyao li .zhongyao-name {
    font-size: 18px;
    text-align: center;
    color: #333333;
}

.zhongyao li:hover {
    border-color: #b76c12;
    cursor: pointer;
}

.zhongyao li:hover .zhongyao-name {
    color: #b76c12;
}

.zhongyao .more-btn {
    display: inline-block;
}

.zhong .more-btn {
    margin: 10px auto;
    /* display: block; */
    width: 124px;
    height: 38px;
    line-height: 38px;
    background-color: #b76c12;
    color: #FFFFFF !important;
    text-align: center;
    cursor: pointer;
}

.left-nav-wrap {
    position: absolute;
    margin-left: 950px;
    width: 260px;
    /* border: 1px solid #e5e5e5; */
    box-shadow: 5px 0 8px rgba(224, 224, 224, 0.3);
    background: #FFFFFF;
}

/deep/ .el-scrollbar .el-scrollbar__wrap {
    max-height: 470px;
    overflow-x: hidden;
}

.fast-nav {
    width: 250px;
    box-sizing: border-box;
    padding: 30px 0 30px 40px;
    z-index: 10;
}

.timeline-item {
    position: relative;
    padding-bottom: 20px;
    padding-top: 10px;
    cursor: pointer;
}

.timeline-item__tail {
    position: absolute;
    top: 15px;
    left: 3px;
    height: 100%;
    border-left: 1px solid #e5e5e5;
}

.timeline-item__node {
    position: absolute;
    background-color: #cccccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 15px;
    left: -1px;
    width: 9px;
    height: 9px;
}

.timeline-item__text {
    position: relative;
    padding-left: 28px;
    top: -3px;
}

.timeline-item__text h3 {
    font-size: 18px;
    color: #333;
    font-weight: normal;
}

.timeline-item.active .timeline-item__node,
.timeline-item:hover .timeline-item__node {
    background-color: #b76c12;
}

.timeline-item.active .timeline-item__text h3,
.timeline-item:hover .timeline-item__text h3 {
    color: #b76c12;
}

/*footer*/
.material #footer {
    background: #333333;
}

.material #footer .footer-con {
    width: 1200px;
    margin: 0 auto;
}

.material #footer .footer-top {
    padding: 40px 0;
}

.material #footer .footer-logo {
    float: left;
    line-height: 80px;
}

.material #footer .footer-logo img {
    margin-top: 15px;
}

.material #footer .footer-nav {
    float: left;
    margin-left: 150px;
    line-height: 80px;
}

.material #footer .footer-nav span {
    margin: 0 10px;
}

.material #footer .footer-nav a,
.footer-nav span {
    color: #ffffff;
}

.material #footer .footer-nav a:hover {
    color: #b76c12;
}

.material #footer .qrcode {
    float: right;
}

.material #footer .qrcode img {
    width: 110px;
}

.material #footer .qrcode>div {
    margin-left: 15px;
}

.material #footer .qrcode div p {
    text-align: center;
    color: #ffffff;
}

.material #footer .copyright {
    padding: 30px;
    border-top: 1px solid rgba(183, 108, 18, 0.2);
}

.material #footer .copyright p {
    color: #ffffff;
    text-align: center;
}

.material #footer .copyright p span {
    color: #b76c12;
}
</style>
<style>
.material .bar-list .yysc {
    /* display: none !important; */
}

.material .el-badge__content.is-dot {
    height: 6px;
    width: 6px;
}

.material .el-badge__content.is-fixed.is-dot {
    right: 0px;
    top: 11px;
}

.material .el-select {
    width: 95px;
}

.material .el-input__inner {
    border: none;
    padding: 0 5px;
}

.material .el-select-dropdown__item.selected {
    color: #b76c12;
    font-weight: 700;
}

.material .el-checkbox {
    width: 33.3%;
    margin: 10px 0;
}

.material .el-checkbox__input.is-checked .el-checkbox__inner,
.material .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #b76c12;
    border-color: #b76c12;
}

.material .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #b76c12;
}

.material .el-checkbox__inner:hover {
    border-color: #b76c12;
}

.material .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #b76c12;
}

.material .el-menu {
    border-right: none;
}

.material .el-submenu__title {
    padding: 0 !important;
    background: #b76c12;
    color: #FFFFFF;
    margin-top: 7px;
}

.material .el-submenu__title:hover {
    background: #b76c12;
}

.material .subtitle .el-submenu.is-opened>.el-submenu__title {
    background: rgba(183, 108, 18, 0.3);
}

.material .el-submenu__title i {
    color: #ffffff;
}

.material .el-menu-item,
.material .el-submenu__title {
    height: 48px;
    line-height: 48px;
}

.material .subtitle {
    border: 1px solid #f2f2f2;
}

.material .subtitle .el-submenu__title i {
    color: #333333;
}

.material .subtitle .el-submenu__title {
    padding-left: 30px !important;
    background: #ffffff;
    color: #333333;
    margin-top: 0;
    height: 36px;
    line-height: 36px;
}

.material .el-menu-item.is-active {
    color: #b76c12;
}

.material .subtitle .el-submenu__title:hover {
    color: #b76c12;
}

.material .el-submenu .el-menu-item {
    padding-left: 50px !important;
    height: 36px;
    line-height: 36px;
}

.material .el-menu-item:focus,
.material .el-menu-item:hover {
    background-color: #ffffff;
}

.material .el-pagination.is-background .btn-next,
.material .el-pagination.is-background .btn-prev,
.material .el-pagination.is-background .el-pager li {
    border-radius: 50%;
}

.material .el-pagination {
    font-weight: 500;
}

.material .el-submenu__title * {
    margin-left: 10px;
}

.material .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #b76c12;
    color: #FFF;
}

/*.material .el-pagination.is-background .el-pager li.number:hover{
        color: #b76c12;
    }*/
.material .el-pagination.is-background .el-pager li:hover {
    color: #b76c12;
}

.material .el-collapse-item__header {
    background-color: #b76c12;
    color: #ffffff;
    font-size: 18px;
    padding: 0 20px;
}

.material .el-collapse-item__header:hover {
    color: #ffffff;
}

.material .el-collapse-item__wrap {
    border-left: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;
}

.material .el-collapse-item__content {
    padding: 15px 5px;
}

.material .el-drawer {
    width: 360px !important;
}

.material .el-drawer__header {
    color: #333;
    padding: 15px 20px;
    background-color: #e0e0e0;
    margin-bottom: 0;
}

.el-autocomplete {
    width: 100%;
}

.material .el-checkbox {
    margin-right: 15px;
}

.material .el-checkbox__label {
    font-size: 16px;
}

.material .el-checkbox__inner {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #ededed;
}

.material .el-checkbox__inner::after {
    border: 2px solid #FFF;
    border-left: 0;
    border-top: 0;
    height: 7px;
    left: 6px;
    top: 3px;
    width: 4px;
}

.material .el-checkbox__input:hover {
    border-color: #DCDFE6;
}

.el-scrollbar__wrap {
    overflow: auto;
}
</style>
